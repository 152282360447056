import { gql } from '@apollo/client';

export const CREATE_FILECASE = gql`
  mutation createFilecase(
    $studio: ID!
    $title: String!
    $externalId: String
    $description: String
    $category: String
    $side: String
    $state: String
    $tags: [String!]
    $assigned: [ID]
    $entities: [ID!]
    $canBeSeenBy: [ID!]
  ) {
    createFilecase(
      studio: $studio
      title: $title
      externalId: $externalId
      category: $category
      side: $side
      description: $description
      state: $state
      tags: $tags
      assigned: $assigned
      entities: $entities
      canBeSeenBy: $canBeSeenBy
    ) {
      id
      title
      externalId
      description
      updatedAt
      category {
        id
        name
      }
      state {
        id
        name
      }
      side {
        id
        name
      }
      tags {
        id
        name
        studioId
      }
      createdBy {
        id
        email
      }
    }
  }
`;

export const UPDATE_FILECASE = gql`
  mutation updateFilecase(
    $studio: ID!
    $filecase: ID!
    $title: String!
    $description: String
    $externalId: String
    $category: String
    $side: String
    $state: String
    $tags: [String!]
    $assigned: [ID]
    $canBeSeenBy: [ID!]
  ) {
    updateFilecase(
      studio: $studio
      filecase: $filecase
      title: $title
      description: $description
      externalId: $externalId
      category: $category
      side: $side
      state: $state
      tags: $tags
      assigned: $assigned
      canBeSeenBy: $canBeSeenBy
    ) {
      id
      title
      externalId
      description
      updatedAt
      stateUpdatedAt

      customFields {
        id
        name
        studioId
        type
        value(modelId: $filecase, modelType: Filecase)
      }

      category {
        id
        name
      }

      state {
        id
        color
        name
      }

      side {
        id
        name
      }

      tags {
        id
        name
        studioId
        updatedAt
      }

      canBeSeenBy {
        id
        name
        email
        picture
      }

      assigned {
        id
        name
        picture
      }
    }
  }
`;

export const ADD_ENTITY_TO_FILECASE = gql`
  mutation addEntityToFilecase($filecase: ID!, $entity: ID!, $studioId: ID!) {
    addEntityToFilecase(
      filecase: $filecase
      entity: $entity
      studioId: $studioId
    ) {
      id
      entities {
        id
        type
        displayName
        firstname
        lastname
        businessname
      }
    }
  }
`;

export const CREATE_ENTRY = gql`
  mutation createEntry(
    $filecase: ID!
    $content: String
    $date: DateTime!
    $studioId: ID!
  ) {
    createEntry(
      filecase: $filecase
      content: $content
      date: $date
      studioId: $studioId
    ) {
      id
      date
      content
      createdAt
      updatedAt
      createdBy {
        id
        picture
        name
      }
    }
  }
`;

export const UPDATE_ENTRY = gql`
  mutation updateEntry(
    $entry: ID!
    $content: String
    $date: DateTime
    $studioId: ID!
  ) {
    updateEntry(
      entry: $entry
      content: $content
      date: $date
      studioId: $studioId
    ) {
      id
      date
      content
      createdAt
      updatedAt
      createdBy {
        id
        picture
        name
      }
    }
  }
`;

export const DELETE_ENTRY = gql`
  mutation deleteEntry($entry: ID!, $studioId: ID!) {
    deleteEntry(entry: $entry, studioId: $studioId) {
      id
    }
  }
`;

export const DELETE_ENTITY_FROM_FILECASE = gql`
  mutation deleteEntityFromFilecase(
    $entity: ID!
    $filecase: ID!
    $studioId: ID!
  ) {
    popEntityFromFilecase(
      entity: $entity
      filecase: $filecase
      studioId: $studioId
    ) {
      id
      title
      externalId
      description
      updatedAt
      totalHours

      entities {
        id
        displayName
        type
        phone
        email
        identification
        country
        address
        state {
          id
          name
          color
        }
      }
    }
  }
`;

export const DELETE_FILECASE = gql`
  mutation deleteFilecase($filecaseIds: [ID!]!, $studio: ID!) {
    deleteFilecase(filecaseIds: $filecaseIds, studio: $studio)
  }
`;
export const MARK_AS_FAVORITE = gql`
  mutation markAsFavorite($id: ID!, $studioId: ID!) {
    markAsFavorite(id: $id, studioId: $studioId) {
      id
    }
  }
`;
