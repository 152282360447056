import React from 'react';
import {
  Avatar,
  Typography,
  Stack,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Box,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { LSCalendar } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';
import { useNavigate } from 'react-router-dom';

const LogsQuery = gql`
  query LogsQuery($studioId: ID!) {
    logs(
      studioId: $studioId
      orderBy: "createdAt"
      sortOrder: "desc"
      limit: 7
    ) {
      id
      createdAt
      user {
        id
        name
        picture
      }
      where
      operation
      model
      after
    }
  }
`;

function humanizeLog(log) {
  const { model, operation, createdAt } = log;
  const formattedDate = dayjs(createdAt).fromNow();
  let action = '';
  let modelName = '';

  switch (operation) {
    case 'create':
      action = 'creó';
      break;
    case 'update':
      action = 'editó';
      break;
    case 'delete':
      action = 'borró';
      break;
    default:
      action = 'modificó';
  }

  switch (model) {
    case 'Event':
      modelName = 'un evento';
      break;
    case 'Sale':
      modelName = 'un honorario';
      break;
    case 'Entity':
      modelName = 'un directorio';
      break;
    case 'Document':
      modelName = 'un documento';
      break;
    case 'Filecase':
      modelName = 'un expediente';
      break;
    case 'Entry':
      modelName = 'una actuación';
      break;
    case 'Calendar':
      modelName = 'un calendario';
      break;
    default:
      modelName = 'un elemento';
  }

  return {
    ...log,
    action,
    modelName,
    formattedDate,
  };
}

const useHandleLogItemClick = (log) => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const dispatchEventDialog = useDialog('event');
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');
  const dispatchSaleDialog = useDialog('sale');
  const studioId = useStudioId();
  const navigate = useNavigate();

  const modelHandlers = {
    Event: () =>
      dispatchEventDialog(
        log.where.id ?? log.after.id ?? log.where.googleEventId,
        {
          id: log.where.id ?? log.after.id ?? log.where.googleEventId,
          calendarId: log.where.calendarId ?? log.after.calendarId,
        },
      ),
    Filecase: () =>
      dispatchFilecaseDialog(log.where.id, {
        id: log.where.id,
      }),
    Document: () =>
      dispatchDocumentPreviewDialog(log.where.id, {
        document: log.after,
        downloable: true,
      }),
    Sale: () =>
      dispatchSaleDialog(log.where.id, {
        id: log.where.id,
      }),
    Entity: () =>
      navigate(`/dashboard/${studioId}/directories/${log.after.id}`),
    Calendar: () => navigate(`/dashboard/${studioId}/calendar`),
  };

  const isClickable =
    !!Object.keys(log.where).length && modelHandlers[log.model];

  const handleClick = () => {
    if (!isClickable) {
      return;
    }

    modelHandlers?.[log.model]?.();
  };

  return { handleClick, isClickable };
};

const LogEntry = ({ log }) => {
  const { handleClick, isClickable } = useHandleLogItemClick(log);

  const primary = (
    <Typography fontWeight="500" fontSize={12}>
      <b>{log.user.name}</b> {log.action}{' '}
      <Box
        component="span"
        sx={{
          fontWeight: '700',
          ...(isClickable
            ? {
                color: 'primary.main',
                textDecoration: 'underline',
                cursor: 'pointer',
              }
            : {}),
        }}
        onClick={isClickable ? handleClick : undefined}
      >
        {log.modelName}
      </Box>{' '}
      {log.formattedDate}
    </Typography>
  );

  return (
    <ListItem sx={{ gap: 1 }}>
      <ListItemAvatar sx={{ minWidth: 'initial' }}>
        <Avatar
          src={log.user.picture}
          alt={log.user.name}
          size="small"
          sx={{ width: 28, height: 28 }}
        />
      </ListItemAvatar>

      <ListItemText primary={primary} />
    </ListItem>
  );
};

export const HomeLogs = () => {
  const { data: { logs = [] } = {}, loading } = useQuery(LogsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      studioId: useStudioId(),
    },
  });

  return (
    <DisabledBackdrop withIndicator in={loading}>
      <Card>
        <CardContent>
          <Stack gap={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <LSCalendar color="primary" />

              <Typography variant="h6" fontWeight="600">
                Actividad reciente
              </Typography>
            </Stack>

            <Divider sx={{ mx: -2 }} />

            <List sx={{ py: 0 }}>
              {logs.map(humanizeLog).map((log) => (
                <LogEntry key={log.id} log={log} />
              ))}
            </List>
          </Stack>
        </CardContent>
      </Card>
    </DisabledBackdrop>
  );
};
