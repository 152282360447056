import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Box, Button, MenuItem, Paper, Typography } from '@mui/material';
import EmptyState from 'src/components/v3/EmptyState';
import { LSAdd } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';

export const GetEntitiesAutocompleteQuery = gql`
  query GetEntitiesAutocompleteQuery($studio: ID!) {
    entities(studio: $studio) {
      id
      displayName
      email
      phone
    }
  }
`;

const EntitiesAutocomplete = ({
  disabled,
  label = 'Asociar Persona',
  placeholder = 'Ej. Cliente / Juzgado / Empresa',
  options,
  ...rest
}) => {
  const studioId = useStudioId();
  const hasPermissions = usePermissions([LS_PERMISSIONS_MAP.DIRECTORY.CREATE]);
  const dispatchEntityForm = useDialog('entityForm');
  const { loading, data: { entities } = {} } = useQuery(
    GetEntitiesAutocompleteQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { studio: studioId },
      skip: Array.isArray(options),
    },
  );

  const opts =
    (entities &&
      entities.map((entity) => ({
        value: entity.id,
        label: entity.displayName,
        search: entity.phone + entity.email,
      }))) ||
    [];

  return (
    <SelectField
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;

        return (
          <Paper {...restPaperProps}>
            {hasPermissions && opts?.length === 0 && (
              <MenuItem
                onMouseDown={(e) => e.preventDefault()}
                onClick={(event) => {
                  event.preventDefault();
                  dispatchEntityForm('EntitiesAutocomplete');
                }}
              >
                <Box py={1}>
                  <EmptyState color="primary">
                    <Typography color="primary" fontWeight="bold">
                      Aún no posees ninguna persona en tu directorio
                    </Typography>

                    <Typography color="primary" sx={{ textWrap: 'wrap' }}>
                      Comienza ahora mismo a digitalizar tus clientes, juzgados,
                      partes intervinientes y mucho más.
                    </Typography>

                    <Box>
                      <Button
                        color="primary"
                        startIcon={<LSAdd color="white" />}
                        variant="contained"
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        Crear directorio
                      </Button>
                    </Box>
                  </EmptyState>
                </Box>
              </MenuItem>
            )}

            {children}
          </Paper>
        );
      }}
      disabled={disabled || loading}
      label={label}
      loading={loading}
      options={options || opts}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default EntitiesAutocomplete;
