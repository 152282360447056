import React from 'react';
import * as Sentry from '@sentry/react';
import { DialogTitle, Dialog, Divider } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';

import DirectoryForm from 'src/forms/DirectoryForm';
import { useBreakpoints } from 'src/utils/hooks/common';

import { CREATE_ENTITY } from 'src/graphql/mutations/entities';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GetEntitiesAutocompleteQuery } from 'src/autocompletes/EntitiesAutocomplete';

const DirectoryDialog = ({ onClose, open, filecaseId, title }) => {
  const studioId = useStudioId();
  const { isMobile } = useBreakpoints();

  const { openSnackbar } = useSnackbar();
  const [createEntity] = useMutation(CREATE_ENTITY, {
    refetchQueries: ['paginatedEntities', 'entities'],
    update: (cache, result) => {
      if (filecaseId) {
        cache.modify({
          id: cache.identify({
            __typename: 'Filecase',
            id: filecaseId,
          }),
          fields: {
            entities(entities) {
              return [
                ...entities,
                { __ref: cache.identify(result.data.createEntity) },
              ];
            },
          },
        });
      }

      cache.writeQuery({
        query: GetEntitiesAutocompleteQuery,
        variables: {
          studio: studioId,
        },
        data: {
          entities: [
            ...(cache.readQuery({
              query: GetEntitiesAutocompleteQuery,
              variables: {
                studio: studioId,
              },
            })?.entities || []),
            result.data.createEntity,
          ],
        },
      });
    },
  });

  const handleSubmit = async (values, formikBag) => {
    try {
      await createEntity({
        variables: {
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),
          studio: studioId,
          state: values?.state?.name,
          type: values.type.value,
          phone: values.phone,
          firstname: values.firstname ? values.firstname : '',
          lastname: values.lastname ? values.lastname : '',
          businessname: values.businessname ? values.businessname : '',
          filecases: filecaseId ? [filecaseId] : null,
        },
      });

      onClose();
      openSnackbar('Entidad creada exitosamente.', {
        severity: 'success',
      });

      formikBag.resetForm();
    } catch (error) {
      openSnackbar(
        'Hubo un error al crear tu entidad. Intenta de nuevo en unos minutos',
        {
          severity: 'error',
        },
      );
      Sentry.captureException(error);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <DialogTitle>{title ?? 'Crear directorio'} </DialogTitle>

      <Divider />

      <DirectoryForm
        enableReinitialize
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DirectoryDialog;
